import { AxiosInstance, AxiosResponse } from 'axios'
import {
  convertOrder,
  convertCustomerOrder,
  convertCustomerOrders,
  convertShipmentTypes,
} from '~/lib/api/deserializers/order'
import {
  Order,
  CustomerOrdersRequest,
  CustomerOrder,
  ShipmentType,
} from '~/types/order'

export default function (instance: AxiosInstance) {
  const base = '/api/aspos/orders'

  return {
    async getOrder(id: number): Promise<Order | null> {
      const response: AxiosResponse = await instance.get(
        `${base}/success/${id}?timestamp=${new Date().getTime()}`
      )
      if (!response.data?.Order) return null

      return convertOrder(response.data.Order, {
        ...response.data.Totals,
        StoreUrl: response.data?.StoreUrl,
      })
    },

    async getOrderCount(): Promise<{ data: number } | null> {
      const response: AxiosResponse = await instance.get(
        `/api/erica/orders/count`
      )

      if (!response.data?.Order) return null

      return {
        data: response.data?.Data ?? 0,
      }
    },

    async getCustomerOrders(
      params: CustomerOrdersRequest
    ): Promise<CustomerOrder[]> {
      const response: AxiosResponse = await instance.get(
        `/api/aspos/customer/orders`,
        {
          params: {
            checkProductsInMainTree: true,
            ...params,
          },
        }
      )
      if (!response.data?.Orders) return []

      return convertCustomerOrders(
        response.data?.Orders,
        response.data?.ProductUrls,
        response.data?.ProductsInMainTree
      )
    },

    async getCustomerOrderById(id: number): Promise<CustomerOrder | null> {
      const response: AxiosResponse = await instance.get(
        `/api/aspos/customer/orders/${id}`
      )

      if (!response.data?.Order) return null

      return convertCustomerOrder(response.data?.Order)
    },

    async getShipmentTypes(): Promise<ShipmentType[] | null> {
      const response: AxiosResponse = await instance.get(
        `/api/aspos/customer/shipment-types`
      )

      if (!response.data?.ShipmentTypes) return null

      return convertShipmentTypes(response.data?.ShipmentTypes)
    },
  }
}
