import axios, { AxiosResponse } from 'axios'
import { Context } from '@nuxt/types'
import { Coordinate } from '@/types/geo'

export default function (_, { $config }: Context) {
  const base = 'maps/api/geocode/json'
  const mapKey = $config.GOOGLE_MAP_KEY

  return {
    async getCoordinateFromAddress(
      address: string
    ): Promise<Coordinate | null> {
      try {
        const response: AxiosResponse = await axios.get(`/${base}`, {
          params: {
            address,
            key: mapKey,
          },
        })
        return response.data?.results?.[0]?.geometry?.location ?? null
      } catch {
        return null
      }
    },

    async getCoordinateFromPostcode(
      postcode: string
    ): Promise<Coordinate | null> {
      try {
        const response: AxiosResponse = await axios.get(`/${base}`, {
          params: {
            address: postcode,
            components: 'country:NL',
            key: mapKey,
          },
        })
        return response.data?.results?.[0]?.geometry?.location ?? null
      } catch {
        return null
      }
    },
  }
}
