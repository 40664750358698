import { Dictionary } from 'ramda'
import { AxiosInstance } from 'axios'
import { Context } from '@nuxt/types'
import { TypeMap, PartMeta } from './content.meta'
import { ContentRequest, ContentResult } from '~/types/content'
import { ApiStatus } from '~/types/common'
import { convertBanner, convertImage } from '~/lib/api/deserializers/content'
import { inRange } from '~/lib/utilities/datetime'
import { decodeHtml } from '~/lib/utilities'

export default function (instance: AxiosInstance, { app }: Context) {
  return {
    async getContent(
      contentRequest: ContentRequest,
      params?: Dictionary<string>
    ): Promise<ContentResult | null> {
      const request = {
        AtomType: TypeMap.get(contentRequest.type || 'Content'),
        Url: contentRequest.objUrl,
        PathUrl: contentRequest.pathUrl,
        Culture: app.i18n.localeProperties.iso,
        GetUrlLocales: true
      }
      if (contentRequest.parts) {
        contentRequest.parts.forEach((part) => {
          PartMeta.get(part)?.request(request, params)
        })
      }

      const response = await instance.post(`/api/vm/u`, request)
      // eslint-disable-next-line no-console
      console.log('vm/u request', request)

      // Handle failure response
      if (response.status === 404 || response.data?.ReasonCode === '404')
        return { status: ApiStatus.NotFound }

      const article = response.data.Article
      if (!article) return { status: ApiStatus.InvalidData }

      const result: ContentResult = { status: ApiStatus.Ok }
      if (contentRequest.pageMeta) {
        result.pageMeta = {
          title: article.Title,
          description: article.Description ? decodeHtml(article.Description) : '',
          img: article.Media?.ImageUrl,
          keywords: article.Keywords,
        }
      }

      result.article = {
        title: article.Title,
        text: article.Text ? decodeHtml(article.Text) : '',
        description: article.Description ? decodeHtml(article.Description) : '',
        summary: article.Summary ? decodeHtml(article.Summary) : '',
        heading: article.Heading ? decodeHtml(article.Heading) : '',
        customField1: article.CustomField1,
        customWidget1: article.CustomWidget1,
        customWidgetSetting1: article.CustomWidgetSetting1,
        robotsNoIndex: article.HideFromRobots,
        robotsNoFollow: article.DontFollowLinks,

        banners: article.Banners?.filter(
          (banner: any) => inRange(banner.PublicationDate, banner.RemovalDate))
          .map(convertBanner),
        images: article.Images?.map(convertImage),

        useBlocks: article.UseBlocks,
      }

      if (article.UrlLocales) {
        const urlLocales = {}
        Object.getOwnPropertyNames(article.UrlLocales).forEach(culture => {
          const urlObj = article.UrlLocales[culture]
          const pathUrl = urlObj['Content.PathUrl']
          const url = urlObj['Content.Url']
          if (url) {
            const lang = culture.split('-')[0]
            if (pathUrl && pathUrl.length) {
              urlLocales[lang] = pathUrl + '/' + url
            } else {
              urlLocales[lang] = url
            }
          }
        })

        if (Object.getOwnPropertyNames(urlLocales).length) {
          result.article.urlLocales = urlLocales
        }
    }

      if (contentRequest.parts) {
        result.parts = contentRequest.parts.reduce((parts, part) => {
          parts[part] = PartMeta.get(part)?.response(article) || null
          return parts
        }, {})
      }

      return result
    },
  }
}
