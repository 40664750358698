import { AxiosInstance, AxiosResponse } from 'axios'
import { Context } from '@nuxt/types'
import { ApiResult } from '~/types/common'
import { LoginParams, RegisterParams, ResetPasswordParams } from '~/types/user'

export default function (instance: AxiosInstance, { app }: Context) {
  const base = 'api/aspos/authentication'

  return {
    async login(payload: LoginParams): Promise<ApiResult<string>> {
      const response: AxiosResponse = await instance.post(
        `/${base}/login`,
        payload
      )
      if (!response.data.Success) return { message: response.data?.Message }

      return { data: response.data?.Token }
    },

    async logout(): Promise<ApiResult<boolean>> {
      const response: AxiosResponse = await instance.post(`/${base}/logout`)
      if (!response.data.Success) return { message: response.data.Message }

      return { data: true }
    },

    async register(params: RegisterParams): Promise<ApiResult<string>> {
      const response: AxiosResponse = await instance.post(
        `/${base}/register`,
        params
      )
      if (!response.data.Success)
        return {
          message:
            response.data?.Message ?? app.i18n.t('message.register.fail'),
        }

      return { data: response.data?.Token }
    },

    async forgotPassword(email: string): Promise<ApiResult<boolean>> {
      const response: AxiosResponse = await instance.post(
        `/${base}/forgotPassword`,
        {
          email,
        }
      )

      if (!response.data.Success) return { message: response.data?.Message }

      return {
        data: response.data?.Success,
        message: response.data?.Message,
      }
    },

    async resetPassword(
      params: ResetPasswordParams
    ): Promise<ApiResult<string>> {
      const response: AxiosResponse = await instance.post(
        `/${base}/resetPassword`,
        params
      )
      if (!response.data.Success) return { message: response.data?.Message }

      return { data: response.data?.Success }
    },

    async checkForgotPasswordToken(token: string): Promise<ApiResult<boolean>> {
      const response: AxiosResponse = await instance.get(
        `/${base}/checkForgotPasswordToken`,
        { params: { token } }
      )
      if (!response.data.Success) return { message: response.data?.Message }

      return { data: response.data?.Success }
    },

    async checkEmail(email: string): Promise<any> {
      const response: AxiosResponse = await instance.get(
        `/${base}/checkIfEmailExists`,
        {
          params: {
            email,
            detailMode: true,
            mode: 'register',
          },
        }
      )

      return !response.data?.Exists
    },
  }
}
