import { AxiosInstance, AxiosResponse } from 'axios'
import { Dictionary } from 'ramda'
import { Context } from '@nuxt/types'
import {
  convertNewsCategory,
  convertNewsItem,
} from '~/lib/api/deserializers/blog'
import { NewsCategory, NewsItem } from '~/types/blog'

export default function (instance: AxiosInstance, { i18n }: Context) {
  return {
    async getNewsCategoryByName(
      name: string,
      params?: Dictionary<any>
    ): Promise<NewsCategory | null> {
      const response: AxiosResponse = await instance.get(
        `/api/newscategorybyname/${name}/${i18n.localeProperties.iso}`,
        {
          params,
        }
      )

      if (!response.data.Success) return null

      return convertNewsCategory({
        ...response.data.NewsCategory,
        NewsItemsCount: response.data.NewsItemsCount,
      })
    },

    async getNewsCategoryById(
      id: string,
      params?: Dictionary<any>
    ): Promise<NewsCategory | null> {
      const response: AxiosResponse = await instance.get(
        `/api/newscategory/${id}/${i18n.localeProperties.iso}`,
        {
          params,
        }
      )

      if (!response.data.Success) return null

      return convertNewsCategory({
        ...response.data.NewsCategory,
        NewsItemsCount: response.data.NewsItemsCount,
      })
    },

    async getNewsItemByName(
      name: string,
      params?: Dictionary<any>
    ): Promise<NewsItem | null> {
      const response: AxiosResponse = await instance.get(
        `/api/newsitembyname/${name}/${i18n.localeProperties.iso}`,
        {
          params,
        }
      )

      if (!response.data.Success) return null

      return convertNewsItem(response.data.NewsItem)
    },

    async getContentNewsItemById(
      id: string,
      params?: Dictionary<any>
    ): Promise<NewsItem | null> {
      const response: AxiosResponse = await instance.get(
        `/api/content/newsitem/${id}/${i18n.localeProperties.iso}`,
        {
          params,
        }
      )

      if (!response.data.Success) return null

      return convertNewsItem(response.data.NewsItem)
    },
  }
}
