import { Context } from '@nuxt/types'
import { AxiosInstance, AxiosResponse } from 'axios'
import { convertFaq } from '~/lib/api/deserializers/faq'
import { Faq } from '~/types/faq'

export default function (instance: AxiosInstance, { i18n }: Context) {
  const base = '/api/faqcategorybyname'
  const culture = i18n.localeProperties.iso ?? 'nl-NL'

  return {
    async getFaqByName(name: string): Promise<Faq | null> {
      const response: AxiosResponse = await instance.get(
        `${base}/${name}/${culture}`
      )

      if (!response.data.Success) return null
      return convertFaq(response.data.FaqCategory)
    },
  }
}
