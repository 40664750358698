import config from './config'
import location from './location'
import webNode from './webNode'
import brand from './brand'
import product from './product'
import productMeili from './product.meili'
import cart from './cart'
import customer from './customer'
import preference from './preference'
import auth from './auth'
import customerAddress from './customer/address'
import wishlist from './wishlist'
import content from './content'
import faq from './faq'
import form from './form'
import order from './order'
import store from './store'
import payment from './payment'
import transaction from "./transactions"
import transsmart from "./transsmart"
import shipping from "./shipping"
import promotion from './promotion'
import blog from './blog'


const resources = {
  config,
  location,

  webNode,
  brand,
  product,
  productMeili,

  cart,
  payment,

  auth,
  customer,
  customerAddress,
  preference,
  wishlist,

  content,
  faq,
  form,

  order,
  transsmart,
  shipping,
  transaction,
  store,
  promotion,
  blog,
}

export type API = {
  [K in keyof typeof resources]: ReturnType<typeof resources[K]>
}

export default resources
